<template>
  <div>
    <Navbar />
    <div id="main-content">
      <router-view />
    </div>
    <Footer />
    <ReminderModal ref="zds-reminder-modal" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
  components: {
    Navbar: () => import("./Navbar.vue"),
    Footer: () => import("./Footer.vue"),
    ReminderModal: () => import("/components/ReminderModal.vue"),
  },
  computed: {
    ...mapGetters("auth", ["isLoggedIn", "user"]),
    isAdmin() {
      return this.user && this.user.type === "teacher";
    },
  },
  mounted() {
    this._showZdsReminderModal();
  },
  methods: {
    async _hash(text) {
      const enc = new TextEncoder();
      const data = enc.encode(text);
      const arrayBuffer = await crypto.subtle.digest("SHA-256", data);
      const hash = Buffer.from(arrayBuffer).toString("base64");

      return hash;
    },
    async _showZdsReminderModal() {
      const listOfUsers = [
        "lqBGkhjEh/Bv1UW0g171a4fA9gfza5/z2Wr8wRDBkxo=",
        "LDqdNMqbO9Jk4t/J3XgUu50kONhWhfGXzCo1dcKDtz4=",
        "eoDyC+N6MnRI8RVZAZcbfYHORXz+AoPBsxNQZmn0SgA=",
        "FGwNknKYhSouh5kyuFPFWaaYHOo/oVhVZJF+zc0RN0k=",
        "6/VUV/+bxrOIIkHJN5POnULBKHCES/2E6kCmhfP4pLw=",
        "qMaJT3l9rmDltXt0UGWfntQOt4UP+jVpkQnJqhb1ytE=",
        "uh4q26kK8PGJwVhzkUke7Nbz3W84GBADcMRwMiox93U=",
        "YM9Z0umndCOel3rGkHNwq3ghAKnhmc4+6an2GMOJHWs=",
        "fqcGpB0sNcfiQF7ur4lQXJ6FAYXHnshIJhLDsKaM90o=",
        "pAerLlGn6WytXTi84owYSgANva7VuNDAOr+eWGo+f/w=",
        "W+pcW787KV4+9dUQYw9e9Rf/FD1lnQErXPtmPMmwFB0=",
        "mwqwV4TaWKCUhUE9OztHHc0+TmbwF7PiZSLMRGyPWOk=",
        "AWIhfytq/lD8XJzpb11Fif2r7f6XsGV+hjBtmr0Djck=",
        "Av1iHyw4zHD9QCSLfGkKbyGIeGjDT999j9qx3wpkk/8=",
        "+zygh665mvxZkH9q0lOX6Pw6ROQEvRNFfqwdAyn5r9Y=",
        "Dx0tuoDi9dE4rIr/fTbPRekdSQZQqmlQ9eK8ZZYbI24=",
        "no5obpZMtAbmPJRLIP7Y8hJnJfa30bVXZuInyvhOTVY=",
        "m7I65XSqH3vN59Xg53Zjotp3s8SFsEC0tfVLlGs7fjs=",
        "3JCVP9Ct9NJizLShBy/o0bZbJZeReqe1GVTW7IIt9V4=",
        "1QjzRU3jHmVz5r7XTNWf2IC9jXdb9kFLyGhBBlU27ao=",
        "pDEdhF8cB3qJjgfqaC7794BBJdF1lWHhb3NQxgenS0k=",
        "z9J7tzuFThthb+M8geYsn/zFEPVR4nfJOskGz2Cton4=",
        "uvY+NS7B8AbtcfgrPBF7hiJ7zdDBckW/4OvwQVm9+Qw=",
        "x2FKFX99NqrZ5yPHB5Gc/egil5q41nBKDZliuG0q3w8=",
        "TcsaYai5Zf3gBN/uqb21rqhQ3l5chqP3R6u0hdn7LFQ=",
        "j2Cjrner6m5uWZBwLQ3xR50Cu/pAO5icDspxXVSD4CQ=",
        "3Rws5a1PZOdPsnHmD2U2tNccTBXu/LEPWeO4vZAY2GY=",
        "Yp6sI9JX9b1Kud1TyuS37mjqZto1tyN1ERW7QmTqaLk=",
        "4JA2QUbegtEDHSclm05rb7SFIqlPmafihNVEmC1719w=",
        "vvVwak5e0YkLn6z1I/XGZAvuLB9GkUX9SzI5wnjklE8=",
        "YeX4wkyUEmM0QzYDBTVIh+DdlzH8cYZLEjG0Scgk9fs=",
        "6jadhaja7141TNOtXNEysTAMIm6x0YihRj8XoQ0w3L0=",
        "7pLeseXDt/PvXV2LDg6WWUS4vV5+uaQjyRqcHJkPnLY=",
        "WjOuXsdMKrZ+ZPv5FFjwiSrfBcR+7BPEiGeitZWlVa4=",
        "DNVLkAcC/A1h3kzqinswkPcV+pXfnqSHQnPxbtvEE3s=",
        "6b728eLtfE7QkdQYQpWD99F3bxJvI/VJSlY74IUKvhA=",
        "xHtuGXG27Ei08fmWdbAtDvg29yS3ngSdUXLP7UFpPJ0=",
        "mm+CFV9qIU4x2b2w3/DSpVUO7u27p6tyaZY2K4U4SAw=",
        "+Tm7wnffENGlWZ214yAYJ0D/MYq0Wu0R7CgYTQ6rnzA=",
        "ZvOIggZ2LLpf9BIOEQgh5OjyMhw2yHzAfhDFx6FKOFQ=",
        "eOZUfgDdR/U4FYt8WMiN8SwKAEYburINkBu10P1H5ww=",
        "WQfwQcKsqyUqcGjpHMkHYrm0yH4sr+1fO+9+LwMu8/c=",
        "LyARGG0f0KC9Z3Ck9hGZell0QeoMUfzdQxHCIb+mo88=",
      ];

      const count = localStorage.getItem(
        "a-zds-reminder-26-01-2023-modal-count"
      );
      const lastDate = localStorage.getItem(
        "a-zds-reminder-26-01-2023-modal-date"
      );
      const currentDate = moment();
      const userInList = Boolean(
        this.user &&
          this.user.email &&
          listOfUsers.includes(await this._hash(this.user.email))
      );

      const countExceeded = count >= 2;
      const lastViewedToday = lastDate
        ? moment(lastDate).isSame(currentDate, "day")
        : false;

      setTimeout(async () => {
        // !['/profil', '/pomocnik', '/triedy'].includes(this.$route.path) ||

        if (!userInList || countExceeded || lastViewedToday) {
          return;
        }

        const newCount = count ? 2 : 1;
        this.$refs["zds-reminder-modal"].showModal();
        localStorage.setItem("a-zds-reminder-26-01-2023-modal-count", newCount);
        localStorage.setItem(
          "a-zds-reminder-26-01-2023-modal-date",
          currentDate.toISOString()
        );
      }, 10000);
    },
  },
};
</script>
<style lang="sass" scoped>
#main-content
	min-height: 53vh

	// @media screen and (max-width: 767px)
	// 	padding-bottom: 76vh
</style>
